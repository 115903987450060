import { cn } from '@askable/ui/lib/utils';
import { IconBlock } from '@askable/ui/unmod/icon-block';
import { OpinionScaleResult } from '@askable/ui/unmod/opinion-scale';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { Path } from 'containers/Studies/Results/components/Task/FigmaPrototype/Path';
import { BookingTaskResultStatus } from 'generated/graphql';

import type { BookingTaskResultPartial } from 'containers/Studies/Results/data/BookingTaskResults.query';
import type {
  BookingTaskResultDetailsFigmaPrototypePathItem,
  FigmaPrototypePathResult,
  FigmaPrototypeScreen,
  TaskBlockFigmaPrototype,
  TaskBlockMultipleChoiceQuestion,
  TaskBlockOpinionScale,
  TaskBlockOpenAnswer,
} from 'generated/graphql';

interface Props extends Omit<BookingTaskResultPartial, '_task_id'> {
  taskBlock: TaskBlockFigmaPrototype | TaskBlockMultipleChoiceQuestion | TaskBlockOpenAnswer | TaskBlockOpinionScale;
}

interface ConvertPathItemProps {
  id: string;
  path: BookingTaskResultDetailsFigmaPrototypePathItem[];
  status: BookingTaskResultStatus;
  task_started?: number | null;
  task_ended?: number | null;
}

// Map the types for <PathItem>
// @todo this endpoint should return result as FigmaPrototypePathResult
const convertPathItem = ({
  id,
  path,
  status,
  task_started,
  task_ended,
}: ConvertPathItemProps): FigmaPrototypePathResult => {
  const pathStatus =
    status === BookingTaskResultStatus.Completed ? BookingTaskResultStatus.Completed : BookingTaskResultStatus.Skipped;

  const screens: FigmaPrototypeScreen[] = path.map(pathItem => ({
    id: pathItem.node_id,
    image: pathItem.image,
    name: pathItem.image?.name,
    result_status: pathStatus,
    started: task_started ?? 0,
    ended: task_ended ?? null,
  }));

  const calculateMisclickRate = (pathItems: { misclicks: number; clicks: number }[]) => {
    const misclicks = pathItems.reduce((acc: number, pathItem) => acc + pathItem.misclicks, 0);
    const clicks = pathItems.reduce((acc: number, pathItem) => acc + pathItem.clicks, 0);
    return clicks > 0 ? misclicks / clicks : 0;
  };

  return {
    _id: id,
    result_status: pathStatus,
    results: [],
    screens,
    stats: {
      average_duration: Math.max((task_ended ?? 0) - (task_started ?? 0), 0),
      completed_count: status === BookingTaskResultStatus.Completed ? 1 : 0,
      misclick_rate: calculateMisclickRate(path),
      skipped_count: 0,
      total_responses: 1,
    },
  };
};

export const ParticipantResultItem = ({ _id, details, taskBlock, status, task_ended, task_started }: Props) => {
  const { t } = useTranslation();

  return (
    <li id={`result-${_id}`}>
      <div className="flex max-w-[70ch] items-start gap-3">
        <IconBlock type={details.type} />
        <h4 className="font-semibold">{taskBlock.title}</h4>
      </div>

      <div className={cn('pl-12', { '-mt-2': details.type !== 'figma_prototype' })}>
        {match(details.type)
          .with('figma_prototype', () =>
            'path' in details ? (
              <Path
                id={details._path_id ?? ''}
                isIndividual
                path={convertPathItem({
                  id: details._path_id ?? '',
                  path: details.path,
                  status: status as BookingTaskResultStatus,
                  task_started,
                  task_ended,
                })}
              />
            ) : null,
          )
          .with('multiple_choice_question', () =>
            'answers' in details ? (
              <ul className={cn('max-w-[70ch] text-pretty', { 'list-disc pl-4': details.answers.length > 1 })}>
                {details.answers.map(answer => (
                  <li key={answer._id}>{answer.value}</li>
                ))}

                {details.other_answer ? (
                  <li>
                    <span className="italic text-muted-foreground">
                      {t('components.multipleChoiceQuestion.other')}:
                    </span>{' '}
                    {details.other_answer}
                  </li>
                ) : null}
              </ul>
            ) : null,
          )
          .with('open_answer', () =>
            'response' in details && details.response && 'open_answer' in taskBlock ? (
              <div className="whitespace-pre-wrap">{details.response}</div>
            ) : null,
          )
          .with('opinion_scale', () =>
            'answer' in details && details.answer && 'opinion_scale' in taskBlock ? (
              <OpinionScaleResult
                max={taskBlock.opinion_scale?.scale_max ?? 0}
                type={taskBlock.opinion_scale?.scale_type ?? 'numerical'}
                value={details.answer}
              />
            ) : null,
          )
          .exhaustive()}
      </div>
    </li>
  );
};

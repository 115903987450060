import { z } from 'zod';

const welcomeBlockSchema = z.object({
  title: z.string().min(1, { message: 'title.required' }),
  instructions: z.string().optional(),
  type: z.literal('welcome'),
});

const thankYouBlockSchema = z.object({
  title: z.string().min(1, { message: 'title.required' }),
  instructions: z.string().optional(),
  type: z.literal('thank_you'),
});

const figmaPrototypeBlockSchema = z.object({
  title: z.string().min(1, { message: 'task.required' }),
  instructions: z.string().optional(),
  image: z.string().optional().nullable(),
  is_recording_enabled: z.boolean(),
  type: z.literal('figma_prototype'),
  figma_prototype: z.object({
    file_id: z.string().min(1, { message: 'figmaPrototypeFileId.required' }),
    start_screen_id: z.string().min(1, { message: 'figmaPrototypeStartScreenId.required' }),
    goal_screen_id: z.string().min(1, { message: 'figmaPrototypeGoalScreenId.required' }),
  }),
});

export const multipleChoiceQuestionOptionSchema = z.object({
  _id: z.string(),
  value: z.string().min(1, { message: 'multipleChoiceQuestion.optionValueRequired' }),
});

export const multipleChoiceQuestionSchema = z.object({
  options: z.array(multipleChoiceQuestionOptionSchema).min(2, { message: 'multipleChoiceQuestion.minOptions' }),
  has_other_option: z.boolean(),
  is_multiple_select: z.boolean(),
  is_randomised_order: z.boolean(),
});

const multipleChoiceQuestionBlockSchema = z.object({
  title: z.string().min(1, { message: 'question.required' }),
  instructions: z.string().optional(),
  image: z.string().optional().nullable(),
  image_path: z.string().optional().nullable(),
  type: z.literal('multiple_choice_question'),
  multiple_choice_question: multipleChoiceQuestionSchema,
});

export const openAnswerSchema = z.object({
  title: z.string().min(1, { message: 'question.required' }),
  instructions: z.string().optional(),
  type: z.literal('open_answer'),
  open_answer: z.object({
    max_length: z.number().min(0),
  }),
});

export const opinionScaleSchema = z.object({
  is_zero_start: z.boolean().default(false),
  label_high: z.string(),
  label_low: z.string(),
  label_mid: z.string(),
  scale_max: z.number().min(1),
  scale_type: z.enum(['numerical', 'stars', 'emotions']),
});

const opinionScaleBlockSchema = z.object({
  title: z.string().min(1, { message: 'question.required' }),
  instructions: z.string().optional(),
  image: z.string().optional().nullable(),
  image_path: z.string().optional().nullable(),
  type: z.literal('opinion_scale'),
  opinion_scale: opinionScaleSchema,
});

export const taskBlockSchema = z.discriminatedUnion('type', [
  figmaPrototypeBlockSchema,
  multipleChoiceQuestionBlockSchema,
  opinionScaleBlockSchema,
  openAnswerSchema,
]);

export const blockSchema = z.discriminatedUnion('type', [
  welcomeBlockSchema,
  thankYouBlockSchema,
  ...taskBlockSchema.options,
]);

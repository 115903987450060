import type { ReactNode } from 'react';

interface CardProps {
  children: ReactNode;
  headerSlot?: ReactNode;
  id?: string;
}

export const Card = ({ children, headerSlot, id }: CardProps) => {
  return (
    <div className="flex flex-col gap-4 rounded-2xl border-0.5 border-border p-4 md:gap-6 md:p-6" id={id}>
      {headerSlot ? <header className="flex flex-col gap-2">{headerSlot}</header> : null}
      {children}
    </div>
  );
};

import { Button } from '@askable/ui/core/button';
import { Input } from '@askable/ui/core/input';
import { Label } from '@askable/ui/core/label';
import { Skeleton } from '@askable/ui/core/skeleton';
import { cn } from '@askable/ui/lib/utils';
import { useFeatureFlags } from 'feature-flags';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';

import { BookingSubmissions } from 'containers/Studies/Results/data/BookingSubmissions.query';
import { useStudyContext } from 'containers/Studies/StudiesContainer';

import { ParticipantItem } from '../components/Navigation/ParticipantItem';
import { TaskItem } from '../components/Navigation/TaskItem';
import { Tabs } from '../components/Tabs';

type ResultsNavigationTabs = 'tasks' | 'participants';

interface ResultsNavigationProps {
  active?: ResultsNavigationTabs;
}

const PARTICIPANT_LIMIT = 100;

export const ResultsNavigation = ({ active }: ResultsNavigationProps) => {
  const { t } = useTranslation();
  const { UNMODERATED_STUDY_DEV } = useFeatureFlags(['UNMODERATED_STUDY_DEV']);
  const navigate = useNavigate();
  const params = useParams();
  const { study } = useStudyContext();
  const { studyId, taskId, participantId } = params;
  const [activeTab, setActiveTab] = useState<ResultsNavigationTabs>('tasks');
  const [cursor, setCursor] = useState<string | null>(null);
  const [searchQueryParticipants, setSearchQueryParticipants] = useState('');

  const tabs = [
    { label: t('sections.studies.recruit.summary'), value: 'tasks' },
    {
      label: t('sections.studies.results.individualResponsesTitle'),
      value: 'participants',
    },
  ];

  const queryVariables = useMemo(
    () => ({
      after: cursor ?? undefined,
      first: PARTICIPANT_LIMIT,
      filter: {
        _booking_id: studyId,
        // filters: [
        //   {
        //     field: 'status',
        //     method: 'any_of' as const,
        //     values: [{ label: 'completed', value_numeric: null, value_string: 'completed' }],
        //   },
        // ],
        sort: ['status_updated' as const],
      },
    }),
    [studyId, cursor],
  );

  const [{ data, fetching }] = useQuery({
    query: BookingSubmissions,
    variables: queryVariables,
    pause: !studyId || !!participantId || activeTab !== 'participants' || !UNMODERATED_STUDY_DEV, // @todo: replace !taskId with !studyId and fix re-rendering issue
  });

  const participants = useMemo(
    () => data?.bookingSubmissionConnection?.nodes ?? [],
    [data?.bookingSubmissionConnection?.nodes],
  );

  // Basic filter participants by name
  // @todo: filter on backend
  const filteredParticipants = useMemo(() => {
    if (searchQueryParticipants === '') {
      return participants;
    }

    const lowerSearch = searchQueryParticipants.toLowerCase();

    return participants.filter(participant => {
      const firstname = participant?.applicant?.firstname?.toLowerCase() ?? '';
      const lastname = participant?.applicant?.lastname?.toLowerCase() ?? '';

      return firstname.includes(lowerSearch) || lastname.includes(lowerSearch);
    });
  }, [participants, searchQueryParticipants]);

  // We load enough participants that we don't need pagination yet
  const loadMoreParticipants = useCallback(() => {
    if (
      data?.bookingSubmissionConnection?.pageInfo.hasNextPage &&
      data?.bookingSubmissionConnection.pageInfo.endCursor &&
      cursor !== data.bookingSubmissionConnection.pageInfo.endCursor
    ) {
      setCursor(data.bookingSubmissionConnection.pageInfo.endCursor);
    }
  }, [data?.bookingSubmissionConnection?.pageInfo, cursor]);

  const handleTabChange = useCallback(
    (value: ResultsNavigationTabs) => {
      setActiveTab(value);

      // Load first task or participant in the list
      if (value === 'tasks') {
        const taskId = study.config?.unmoderated?.task_blocks?.[0]?._id;
        if (taskId) {
          navigate(`/studies/${studyId}/results/task/${taskId}`);
        }
      } else {
        const participantId = participants?.[0]?.applicant._id;
        if (participantId) {
          navigate(`/studies/${studyId}/results/participant/${participantId}`);
        }
      }
    },
    [participants, navigate, study.config?.unmoderated?.task_blocks, studyId],
  );

  useEffect(() => {
    if (active === 'participants') {
      setActiveTab(active);
    }
  }, [active]);

  return (
    <nav className="page-nav flex h-full flex-col overflow-hidden rounded-xl bg-background pt-3">
      {UNMODERATED_STUDY_DEV ? (
        <div className="px-3">
          <Tabs
            value={activeTab}
            tabs={tabs}
            isFullWidth
            onValueChange={value => handleTabChange(value as ResultsNavigationTabs)}
          />
        </div>
      ) : null}

      <div
        className={cn('w-full flex-1 overflow-auto p-3 lg:p-4', {
          hidden: activeTab !== 'tasks',
        })}
      >
        <ol className="flex flex-col gap-2">
          {study.config?.unmoderated?.task_blocks?.map(taskBlock =>
            taskBlock ? (
              <li key={taskBlock._id}>
                <TaskItem
                  id={taskBlock._id}
                  isActive={taskBlock._id === taskId}
                  title={taskBlock.title}
                  type={taskBlock.type}
                />
              </li>
            ) : null,
          )}
        </ol>
      </div>

      <div className="overflow-auto">
        <div
          className={cn('flex flex-1 flex-col gap-2 overflow-auto p-3 lg:p-4', {
            hidden: activeTab !== 'participants',
          })}
        >
          {fetching && participants.length === 0 ? (
            <>
              <Skeleton className="h-12 w-full" />
              <Skeleton className="h-12 w-full" />
            </>
          ) : null}

          {participants && (participants?.length ?? 0) > 0 ? (
            <>
              <Label htmlFor="filter-participants" className="sr-only">
                {t('sections.studies.results.filter')}
              </Label>

              {/* Hide filter for now */}
              <Input
                className="!hidden w-full bg-background-subtle hover:bg-secondary focus:bg-secondary"
                id="filter-participants"
                placeholder={`${t('sections.studies.results.filter')}...`}
                type="search"
                value={searchQueryParticipants}
                variant="borderless"
                onChange={e => setSearchQueryParticipants(e.target.value)}
              />

              <ol className="flex flex-col gap-2">
                {filteredParticipants.map((participant, index) => (
                  <li key={`nav-${participant?.applicant._id ?? index}`}>
                    <ParticipantItem
                      completed={participant?.status_updated ?? undefined}
                      id={participant?.applicant._id ?? ''}
                      isActive={participant?.applicant._id === participantId}
                      name={
                        participant?.applicant
                          ? [participant?.applicant.firstname, participant?.applicant.lastname]
                              .filter(Boolean)
                              .join(' ')
                          : t('global.unknownUser')
                      }
                    />
                  </li>
                ))}
              </ol>

              {data?.bookingSubmissionConnection?.pageInfo.hasNextPage ? (
                <Button
                  className="w-full"
                  isLoading={fetching && participants?.length > 0}
                  variant="ghost"
                  onClick={loadMoreParticipants}
                >
                  {t('components.notifications.loadMore')}
                </Button>
              ) : null}
            </>
          ) : null}

          {participants?.length === 0 || filteredParticipants.length === 0 ? (
            <div className="py-2 text-sm text-muted-foreground">{t('sections.studies.results.noParticipants')}</div>
          ) : null}
        </div>
      </div>
    </nav>
  );
};

import { graphql } from 'src/graphql';

export const CreateBookingTaskResultOpenAnswerSummary = graphql(`
  mutation CreateBookingTaskResultOpenAnswerSummary($input: CreateBookingTaskResultOpenAnswerSummaryInput!) {
    createBookingTaskResultOpenAnswerSummary(input: $input) {
      _task_id
      created
      response_count
      summary
    }
  }
`);

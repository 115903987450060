import { UserSolid } from '@askable/ui/icons';
import { IconBlock } from '@askable/ui/unmod/icon-block';
import { useTranslation } from 'react-i18next';

import type { BookingTaskResultDetailsType } from 'generated/graphql';

interface TaskHeaderProps {
  responses: number;
  title: string;
  subtitle?: string;
  type: BookingTaskResultDetailsType;
}

export const TaskHeader = ({ responses, title, subtitle, type }: TaskHeaderProps) => {
  const { t } = useTranslation();

  return (
    <header className="flex flex-wrap items-center justify-between gap-2">
      <div className="flex items-center gap-3">
        <IconBlock type={type} size="lg" />

        <div className="flex flex-col">
          <h2 className="text-pretty text-xl font-semibold leading-tight text-foreground text-foreground">{title}</h2>
          <div className="text-sm text-muted-foreground">
            {t(`sections.studies.build.blocks.${type}.title`)} {subtitle ? <span>({subtitle})</span> : null}
          </div>
        </div>
      </div>

      <div className="flex items-center gap-1">
        <UserSolid className="h-4 w-4 text-input" />
        <div className="text-sm text-muted-foreground">
          {responses} {t('sections.studies.results.responses', { count: responses }).toLocaleLowerCase()}
        </div>
      </div>
    </header>
  );
};

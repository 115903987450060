import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { match } from 'ts-pattern';

import { EmptyState } from 'containers/Studies/Results/components/EmptyState';
import { FigmaPrototypeResults } from 'containers/Studies/Results/components/Task/FigmaPrototype/FigmaPrototypeResults';
import { IndividualResponses } from 'containers/Studies/Results/components/Task/IndividualResponses';
import { MultipleChoiceQuestionResults } from 'containers/Studies/Results/components/Task/MultipleChoiceQuestion/MultipleChoiceQuestionResults';
import { OpenAnswerResults } from 'containers/Studies/Results/components/Task/OpenAnswer/OpenAnswerResults';
import { OpinionScaleResults } from 'containers/Studies/Results/components/Task/OpinionScale/OpinionScaleResults';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { getPageTitle } from 'containers/Studies/utils/getPageTitle';
import { TaskBlockType } from 'generated/graphql';

import type { TaskBlockMultipleChoiceQuestion, TaskBlockOpinionScale } from 'generated/graphql';

export const TaskContent = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { study } = useStudyContext();
  const taskBlock = study.config?.unmoderated?.task_blocks?.find(block => block?._id === params.taskId);

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle({
            section: `${t('sections.studies.tabs.results')} - ${taskBlock?.title}`,
            study: study.name || t('sections.studies.untitledStudy'),
          })}
        </title>
      </Helmet>

      <div
        id={`task-block-${taskBlock?._id}`}
        className="flex h-full flex-col gap-10 rounded-xl bg-background p-3 lg:p-8"
      >
        {taskBlock ? (
          <>
            {match(taskBlock.type)
              .with(TaskBlockType.FigmaPrototype, () => (
                <FigmaPrototypeResults taskId={taskBlock?._id} title={taskBlock.title} />
              ))
              .with(TaskBlockType.MultipleChoiceQuestion, () => (
                <MultipleChoiceQuestionResults
                  taskId={taskBlock?._id}
                  title={taskBlock.title}
                  isMultipleSelect={
                    (taskBlock as TaskBlockMultipleChoiceQuestion)?.multiple_choice_question?.is_multiple_select ??
                    false
                  }
                />
              ))
              .with(TaskBlockType.OpenAnswer, () => (
                <OpenAnswerResults taskId={taskBlock?._id} title={taskBlock.title} />
              ))
              .with(TaskBlockType.OpinionScale, () => (
                <OpinionScaleResults
                  labels={[
                    (taskBlock as TaskBlockOpinionScale)?.opinion_scale?.label_low ?? '',
                    (taskBlock as TaskBlockOpinionScale)?.opinion_scale?.label_mid ?? '',
                    (taskBlock as TaskBlockOpinionScale)?.opinion_scale?.label_high ?? '',
                  ]}
                  taskId={taskBlock?._id}
                  title={taskBlock.title}
                />
              ))
              .otherwise(() => null)}

            <IndividualResponses type={taskBlock.type} />
          </>
        ) : (
          <EmptyState />
        )}
      </div>
    </>
  );
};

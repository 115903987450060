import { Tooltip, TooltipContent, TooltipTrigger } from '@askable/ui/core/tooltip';
import { UserSolid, CircleCheckSolid, CircleXSolid, MousePointerClickSolid, TimerSolid } from '@askable/ui/icons';
import { secondsToTime, cn } from '@askable/ui/lib/utils';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { formatPercentage } from 'containers/Studies/utils/utils';

export type StatType =
  | 'averageDuration'
  | 'duration'
  | 'misclickRate'
  | 'reachedGoal'
  | 'reachedGoalFailed'
  | 'responses';

export interface StatProps {
  count?: number;
  variant?: 'block' | 'inline' | 'inline-tooltip';
  type: StatType;
  value: string | number;
}

const iconByType = {
  averageDuration: TimerSolid,
  duration: TimerSolid,
  misclickRate: MousePointerClickSolid,
  reachedGoal: CircleCheckSolid,
  reachedGoalFailed: CircleXSolid,
  responses: UserSolid,
};

export const Stat = ({ count, variant = 'inline-tooltip', type, value }: StatProps) => {
  const { t } = useTranslation();

  const Icon = iconByType[type];
  let localValue = value;

  if (['reachedGoal', 'reachedGoalFailed', 'misclickRate'].includes(type) && typeof value === 'number') {
    localValue = formatPercentage(value);
  }

  // Duration in milliseconds
  if (type === 'averageDuration' || type === 'duration') {
    localValue = secondsToTime(Number(value) / 1000, 'labels', 'decisecond');
  }

  return match(variant)
    .with('block', () => (
      <Tooltip>
        <TooltipTrigger className="flex flex-col justify-center gap-2 rounded-2xl border-0.5 border-border p-4 text-left text-sm leading-none">
          <div className="flex items-center gap-1 font-medium">
            <Icon
              className={cn('relative -top-[1px] h-4 w-4 text-input', {
                'text-success': type === 'reachedGoal',
              })}
            />
            {t(`sections.studies.results.${type}`)}
          </div>
          <div className="flex w-full flex-wrap items-end justify-between gap-x-2 gap-y-1">
            <div className="text-3xl font-semibold leading-none">{localValue}</div>{' '}
            {count ? (
              <div className="flex items-center gap-1 text-muted-foreground">
                <UserSolid className="h-4 w-4 text-input" />
                <span>{count}</span>
              </div>
            ) : null}
          </div>
        </TooltipTrigger>
        <TooltipContent>{t(`sections.studies.results.${type}Description`)}</TooltipContent>
      </Tooltip>
    ))
    .with('inline', () => (
      <div className="flex items-center gap-1 whitespace-nowrap font-medium">
        <Icon
          className={cn('relative -top-[1px] h-4 w-4 text-input', {
            'text-success': type === 'reachedGoal',
          })}
        />
        {localValue}{' '}
        {type !== 'reachedGoal' && type !== 'reachedGoalFailed'
          ? t(`sections.studies.results.${type}`).toLowerCase()
          : null}
      </div>
    ))
    .with('inline-tooltip', () => (
      <Tooltip>
        <TooltipTrigger className="flex items-center gap-1 whitespace-nowrap font-medium">
          <Icon
            className={cn('relative -top-[1px] h-4 w-4 text-input', {
              'text-success': type === 'reachedGoal',
            })}
          />
          {localValue}
        </TooltipTrigger>
        <TooltipContent>{t(`sections.studies.results.${type}`)}</TooltipContent>
      </Tooltip>
    ))
    .exhaustive();
};

import { useCallback, useEffect, useMemo } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { match } from 'ts-pattern';

import { useStudyContext } from 'containers/Studies/StudiesContainer';

import type {
  Maybe,
  SystemBlock,
  TaskBlockFigmaPrototype,
  TaskBlockMultipleChoiceQuestion,
  TaskBlockOpinionScale,
  TaskBlockOpenAnswer,
} from 'generated/graphql';

let lastBlockId: string | undefined;

// TODO: check schema and types to see if there's a better way to resolve `TaskBlock` here
export type TaskBlock =
  | TaskBlockFigmaPrototype
  | TaskBlockMultipleChoiceQuestion
  | TaskBlockOpinionScale
  | TaskBlockOpenAnswer;
export type ActiveBlock = SystemBlock | Maybe<TaskBlock> | undefined;

export const useActiveBlockId = (): {
  activeBlockId: string | null;
  activeBlock: ActiveBlock;
  setActiveBlockId: (newBlockId: string, replace?: boolean) => void;
} => {
  const { study } = useStudyContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const navigate = useNavigate();
  const { taskId } = params;
  const activeBlockId = searchParams.get('block_id');

  const activeBlock = useMemo(() => {
    if (!activeBlockId) {
      return null;
    }

    return match(activeBlockId)
      .with('welcome', () => study.config?.unmoderated?.welcome_block)
      .with('thank_you', () => study.config?.unmoderated?.thank_you_block)
      .otherwise(() => study.config?.unmoderated?.task_blocks?.find(b => b?._id === activeBlockId));
  }, [study, activeBlockId]);

  const setActiveBlockId = useCallback(
    (newBlockId: string, replace?: boolean) => {
      if (newBlockId !== activeBlockId) {
        const searchString = `?${new URLSearchParams({
          ...Object.fromEntries(searchParams),
          block_id: newBlockId,
        }).toString()}${window.location.hash}`;

        if (replace) {
          navigate(searchString, { replace: true });
        } else {
          navigate(searchString);
        }
      }
    },
    [activeBlockId, searchParams, navigate],
  );

  useEffect(() => {
    if (taskId) {
      return;
    }

    if (activeBlockId) {
      lastBlockId = activeBlockId;
      return;
    }

    // If no id in the url, fall back to the last one or to the welcome block which is guaranteed to be there
    // We only want to use the fallback though if it's still a valid block on the current study (e.g. not if the
    // user has navigated to a different study)
    let fallback = 'welcome';

    if (lastBlockId) {
      if (
        ['welcome', 'thank_you'].includes(lastBlockId) ||
        study?.config?.unmoderated?.task_blocks?.find(b => b?._id === lastBlockId)
      ) {
        fallback = lastBlockId;
      }
    }
    setActiveBlockId(fallback, true);
  }, [activeBlockId, setSearchParams, setActiveBlockId, study?.config?.unmoderated?.task_blocks]);

  return {
    activeBlockId,
    setActiveBlockId,
    activeBlock,
  };
};

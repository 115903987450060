import { AnchorButton } from '@askable/ui/core/button';
import { Flame, GitCompare, ListVideo } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Card } from 'containers/Studies/Results/components/Card';
import { ScreenItem } from 'containers/Studies/Results/components/ScreenItem';
import { ScrollableList } from 'containers/Studies/Results/components/ScrollableList';
import { Stat } from 'containers/Studies/Results/components/Stat';
import { BookingTaskResultStatus } from 'generated/graphql';

import type { FigmaPrototypePathResult } from 'generated/graphql';

interface PathProps {
  id: string;
  index?: number;
  /* Path for a single participant result */
  isIndividual?: boolean;
  path: FigmaPrototypePathResult;
}

/**
 * A single path in the Path Analysis section
 */
export const Path = ({ id, index, isIndividual, path }: PathProps) => {
  const { t } = useTranslation();

  return (
    <Card
      id={`path-${id}`}
      headerSlot={
        <>
          <div className="flex flex-wrap items-center justify-between gap-2">
            <h4 className="text-lg font-semibold">
              {t('sections.studies.results.paths', { count: 1 })} {index !== undefined ? index + 1 : null}
            </h4>

            <div className="flex flex-wrap gap-2">
              <AnchorButton variant="outline" asChild>
                <Link to={{ search: `type=path&path_id=${path._id}&tab=recording` }}>
                  <ListVideo className="h-4 w-4" />
                  <span className="sr-only sm:not-sr-only">
                    {t('sections.studies.results.recordings', { count: isIndividual ? 1 : 2 })}
                  </span>
                </Link>
              </AnchorButton>
              <AnchorButton variant="outline" asChild>
                <Link to={{ search: `type=path&path_id=${path._id}&tab=heatmap` }}>
                  <Flame className="h-4 w-4" />
                  <span className="sr-only sm:not-sr-only">
                    {t('sections.studies.results.heatMaps', { count: isIndividual ? 1 : 2 })}
                  </span>
                </Link>
              </AnchorButton>
            </div>
          </div>

          <div className="flex flex-wrap gap-x-3 gap-y-1 text-sm text-muted-foreground">
            {path.result_status === BookingTaskResultStatus.Completed ? (
              <Stat type="reachedGoal" value={t('sections.studies.results.reachedGoal')} variant="inline" />
            ) : null}

            {path.result_status === BookingTaskResultStatus.Skipped ? (
              <Stat type="reachedGoalFailed" value={t('sections.studies.results.reachedGoalFailed')} variant="inline" />
            ) : null}

            {path.stats ? (
              <>
                <Stat type="responses" value={path.stats.completed_count} />
                <Stat type="misclickRate" value={path.stats.misclick_rate} variant="inline" />
                <Stat type="averageDuration" value={path.stats.average_duration} variant="inline" />
              </>
            ) : null}
          </div>
        </>
      }
    >
      {path.screens.length > 0 ? (
        <ScrollableList itemWidth={192}>
          {path.screens.map((screen, index) => (
            <ScreenItem key={screen.id} {...screen} pathId={path._id} resultType="path" index={index} />
          ))}
        </ScrollableList>
      ) : (
        <div className="flex h-full min-h-44 flex-col items-center justify-center gap-2 text-sm text-muted-foreground">
          <GitCompare className="h-6 w-6" />
          {t('sections.studies.results.noPaths')}
        </div>
      )}
    </Card>
  );
};

import { AnchorButton } from '@askable/ui/core/button';
import { DialogTitle } from '@askable/ui/core/dialog';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { match } from 'ts-pattern';

import { Tabs } from 'containers/Studies/Results/components/Tabs';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { getPageTitle } from 'containers/Studies/utils/getPageTitle';

import type { FigmaPrototypeResultType } from 'containers/Studies/Results/containers/FigmaPrototypeResultDialog';
import type { ReactNode } from 'react';

type Direction = 'previous' | 'next';

interface FigmaPrototypeResultDialogHeaderProps {
  activeTab: string;
  children?: ReactNode;
  hasNavigation?: boolean;
  isLoading: boolean;
  nextResultId: string | null;
  previousResultId: string | null;
  showTabs?: boolean;
  title: string;
  type: FigmaPrototypeResultType;
  onTabChange: (tab: string) => void;
}

export const FigmaPrototypeResultDialogHeader = ({
  activeTab,
  children,
  hasNavigation = true,
  isLoading,
  nextResultId,
  previousResultId,
  showTabs = true,
  title,
  type,
  onTabChange,
}: FigmaPrototypeResultDialogHeaderProps) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { study } = useStudyContext();
  const [navigatingDirection, setNavigatingDirection] = useState<Direction>('next');

  const tabs = [
    { label: t('sections.studies.results.recordings', { count: 2 }), value: 'recording' },
    { label: t('sections.studies.results.heatMaps', { count: 1 }), value: 'heatmap' },
  ];

  const getSearchString = (newResultId: string) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());

    match(type)
      .with('individual', () => newSearchParams.set('result_id', newResultId))
      .with('path', () => newSearchParams.set('path_id', newResultId))
      .with('screen', () => newSearchParams.set('node_id', newResultId))
      .exhaustive();

    return newSearchParams.toString();
  };

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle({
            section: `${title} (${type}) - ${t('sections.studies.tabs.results')}`,
            study: study.name || t('sections.studies.untitledStudy'),
          })}
        </title>
      </Helmet>

      <div className="flex flex-col gap-2 border-b border-border p-4">
        <div className="grid grid-cols-1 items-center justify-between gap-2 sm:grid-cols-2 md:grid-cols-3">
          <div className="flex items-center gap-2">
            <DialogTitle className="truncate text-lg font-semibold leading-tight">
              {title || t('global.unknown')}
            </DialogTitle>

            {hasNavigation ? (
              <nav className="flex items-center gap-2">
                <AnchorButton
                  variant="ghost"
                  size="icon"
                  disabled={!previousResultId}
                  isLoading={navigatingDirection === 'previous' && isLoading}
                  onClick={() => setNavigatingDirection('previous')}
                  asChild
                >
                  <Link
                    to={{ search: previousResultId ? getSearchString(previousResultId) : '' }}
                    title={t('global.previous')}
                  >
                    <ChevronLeft className="h-4 w-4" />
                    <span className="sr-only">{t('global.previous')}</span>
                  </Link>
                </AnchorButton>
                <AnchorButton
                  variant="ghost"
                  title={t('global.next')}
                  size="icon"
                  disabled={!nextResultId}
                  isLoading={navigatingDirection === 'next' && isLoading}
                  onClick={() => setNavigatingDirection('next')}
                  asChild
                >
                  <Link to={{ search: nextResultId ? getSearchString(nextResultId) : '' }} title={t('global.next')}>
                    <ChevronRight className="h-4 w-4" />
                    <span className="sr-only">{t('global.next')}</span>
                  </Link>
                </AnchorButton>
              </nav>
            ) : null}
          </div>

          {showTabs ? (
            <nav className="sm:text-center">
              <Tabs value={activeTab} tabs={tabs} onValueChange={onTabChange} />
            </nav>
          ) : null}

          <div />
        </div>
        <div className="flex flex-wrap gap-x-3 gap-y-1 text-sm text-muted-foreground">{children}</div>
      </div>
    </>
  );
};

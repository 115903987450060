import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from 'urql';

import { HeatmapContent } from 'containers/Studies/Results/components/Heatmap/HeatmapContent';
import { RecordingContent } from 'containers/Studies/Results/components/Recording/RecordingContent';
import { Stat } from 'containers/Studies/Results/components/Stat';
import { FigmaPrototypeResultDialogHeader } from 'containers/Studies/Results/components/Task/FigmaPrototype/Result/FigmaPrototypeResultDialogHeader';
import { FigmaPrototypeResultDialogLoading } from 'containers/Studies/Results/components/Task/FigmaPrototype/Result/FigmaPrototypeResultDialogLoading';
import { BookingTaskResult } from 'containers/Studies/Results/data/BookingTaskResult.query';

import type { BookingTaskResultStatus, BookingTaskResultFigmaPrototype } from 'generated/graphql';

/**
 * Render recording and screens for an individual user result
 */
export const FigmaPrototypeResultDialogContentIndividual = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const tabParam = searchParams.get('tab');
  const initialTab = tabParam === 'heatmap' ? 'heatmap' : 'recording';
  const [activeTab, setActiveTab] = useState(initialTab);

  const resultId = searchParams.get('result_id');
  const nodeId = searchParams.get('node_id');

  const [{ data, fetching, error }] = useQuery({
    query: BookingTaskResult,
    variables: { filter: { _id: resultId } },
    pause: !resultId,
  });

  const resultData = data?.bookingTaskResult;
  const title = resultData?.user?.displayName ?? resultData?.user?._id ?? '';
  const status = resultData?.status as BookingTaskResultStatus;

  // For heatmap and video chapters
  const screens =
    resultData?.details && 'path' in resultData.details
      ? resultData.details.path.map(item => ({ ...item, id: item.node_id }))
      : [];
  const activeScreen = screens.find(item => item.node_id === nodeId) ?? screens[0];

  const goalStatus = status === 'completed' ? 'reachedGoal' : 'reachedGoalFailed';
  const duration = Math.max((resultData?.task_ended ?? 0) - (resultData?.task_started ?? 0), 0);

  if (fetching && !resultData) {
    return <FigmaPrototypeResultDialogLoading />;
  }

  if (!fetching && error) {
    return <div className="p-4 text-destructive">{error.message}</div>;
  }

  if (!fetching && resultData) {
    return (
      <>
        <FigmaPrototypeResultDialogHeader
          activeTab={activeTab}
          isLoading={fetching}
          nextResultId={resultData?.nextResultId ?? null}
          previousResultId={resultData?.previousResultId ?? null}
          title={title}
          type="individual"
          onTabChange={setActiveTab}
        >
          <>
            <Stat type={goalStatus} value={t(`sections.studies.results.${goalStatus}`)} variant="inline" />
            <Stat type="duration" value={duration} variant="inline" />
          </>
        </FigmaPrototypeResultDialogHeader>

        {activeTab === 'heatmap' ? (
          <HeatmapContent screens={screens} activeScreen={activeScreen} />
        ) : (
          <RecordingContent
            results={[resultData as BookingTaskResultFigmaPrototype]}
            screens={screens}
            showRelatedResults={false}
            title={title}
          />
        )}
      </>
    );
  }
};

import { Spinner } from '@askable/ui/core/spinner';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from 'urql';

import { EmptyState } from 'containers/Studies/Results/components/EmptyState';
import { Heatmap } from 'containers/Studies/Results/components/Heatmap/Heatmap';
import { HeatmapDetails } from 'containers/Studies/Results/components/Heatmap/HeatmapDetails';
import { HeatmapHeader } from 'containers/Studies/Results/components/Heatmap/HeatmapHeader';
import { ScreenItem } from 'containers/Studies/Results/components/ScreenItem';
import { ScrollableList } from 'containers/Studies/Results/components/ScrollableList';
import { BookingTaskResultClicks } from 'containers/Studies/Results/data/BookingTaskResultClicks.query';

import type { HeatmapView } from 'containers/Studies/Results/components/Heatmap/Heatmap';
import type {
  BookingTaskEventDetailsClick,
  BookingTaskResultClicksFilterInput,
  FigmaPrototypeScreen,
} from 'generated/graphql';

interface HeatmapContentProps {
  activeScreen?: FigmaPrototypeScreen;
  screens: FigmaPrototypeScreen[];
}

const DEFAULT_IMAGE_HEIGHT = 640;

export const HeatmapContent = ({ activeScreen, screens }: HeatmapContentProps) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const { studyId, taskId, participantId } = params;

  // Minimise layout shift while loading, store the screen image height
  const screenHeightRef = useRef<number | null>(null);

  const [activeClicks, setActiveClicks] = useState<number[]>([]);
  const [isDownloading, setIsDownloading] = useState(false);

  const nodeId = searchParams.get('node_id');
  const pathId = searchParams.get('path_id');
  const pathItemIndex = Number(searchParams.get('path_index') ?? 0);
  const resultId = searchParams.get('result_id');
  const type = searchParams.get('type');
  const heatmapView = searchParams.get('heatmap_view') as HeatmapView | null;

  const [activeView, setActiveView] = useState<HeatmapView>(heatmapView ?? 'heatmap');

  const filter: BookingTaskResultClicksFilterInput = {
    _booking_id: studyId ?? '',
    node_id: nodeId ?? '',
  };

  if (pathId) {
    // A screen in a path
    filter._path_id = pathId;
    filter.path_index = pathItemIndex;
  } else if (resultId) {
    // A single screen for a single result
    filter._result_id = resultId;
  }

  // Get clicks for either a task or a participant
  if (participantId) {
    filter._user_id = participantId;
  }

  if (taskId) {
    filter._task_id = taskId;
  }

  const [{ data, fetching }] = useQuery({
    query: BookingTaskResultClicks,
    variables: { filter },
    pause: !(taskId || participantId) || !studyId || !nodeId,
  });

  const clickData = data?.bookingTaskResultClicks ?? [];

  // Calculate stats
  const totalClicks = clickData.length ?? 0;
  const totalMisclicks = clickData.filter(event => !event.handled).length ?? 0;
  const misclickRate = totalClicks > 0 ? totalMisclicks / totalClicks : 0;
  const duration = Math.max(0, (activeScreen?.ended ?? 0) - (activeScreen?.started ?? 0));

  // Download the heatmap triggered from the HeatmapHeader
  const handleDownload = () => {
    setIsDownloading(true);
    setTimeout(() => {
      setIsDownloading(false);
    }, 1000);
  };

  // If no nodeId set then load the click data for the activeScreen
  useEffect(() => {
    if (activeScreen && !nodeId) {
      const newParams = new URLSearchParams(searchParams);
      newParams.set('node_id', activeScreen.id);

      // Set a 400ms delay to fix the race condition with the handleClose callback in FigmaPrototypeResultDialog
      setTimeout(() => setSearchParams(newParams), 400);
    }

    setActiveClicks([]);
  }, [activeScreen]);

  useEffect(() => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('heatmap_view', activeView);
    setSearchParams(newParams);
  }, [activeView, searchParams, setSearchParams]);

  // Update height reference when heatmap is loaded and visible
  useEffect(() => {
    if (!fetching && activeScreen?.image?.height) {
      screenHeightRef.current = activeScreen.image.height;
    }
  }, [fetching, activeScreen]);

  // Set a default container height while activeScreen is loading
  const containerHeight =
    screenHeightRef.current || activeScreen?.image?.height || screens[0]?.image?.height || DEFAULT_IMAGE_HEIGHT;

  return (
    <div className="flex flex-1 flex-col lg:flex-row lg:overflow-hidden">
      <div className="flex min-w-0 flex-1 flex-col bg-secondary">
        {screens.length > 0 ? (
          <>
            <HeatmapHeader
              activeClicks={activeClicks}
              clicksCount={clickData.length}
              title={type !== 'screen' ? activeScreen?.image?.name : undefined}
              view={activeView}
              onActiveClicksChange={setActiveClicks}
              onDownload={handleDownload}
              onViewChange={setActiveView}
            />
            {!fetching && clickData && activeScreen ? (
              <div className="min-h-96 flex-1 overflow-auto">
                {activeScreen?.image ? (
                  <Heatmap
                    key={pathId ?? activeScreen.id}
                    clicks={(clickData as BookingTaskEventDetailsClick[]) ?? []}
                    image={activeScreen.image}
                    activeClicks={activeClicks}
                    isDownloading={isDownloading}
                    view={activeView}
                  />
                ) : null}
              </div>
            ) : (
              <>
                <div className="h-14 w-full" />
                <div className="flex items-center justify-center" style={{ height: `${containerHeight}px` }}>
                  <Spinner className="h-6 w-6" />
                </div>
              </>
            )}

            <div className="px-5 pt-2">
              <ScrollableList itemWidth={64} isCentered>
                {screens.map((item, index) => (
                  <ScreenItem
                    key={item.id}
                    {...item}
                    index={index}
                    pathId={pathId ?? undefined}
                    isActive={pathItemIndex ? pathItemIndex === index : item.id === nodeId}
                  />
                ))}
              </ScrollableList>
            </div>
          </>
        ) : (
          <div className="min-h-96">
            <EmptyState title={t('sections.studies.results.screen.notFound')} />
          </div>
        )}
      </div>

      <HeatmapDetails clickCount={clickData.length} duration={duration} misclickRate={misclickRate} />
    </div>
  );
};

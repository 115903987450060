import { Label } from '@askable/ui/core/label';
import { Cog, Explore, Target } from '@askable/ui/unmod/icons';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SectionContainer } from 'containers/Studies/Recruit/components/SectionContainer';
import { SummaryItem } from 'containers/Studies/Recruit/components/Summary/SummaryItem';
import { SummaryItemCustomTerms } from 'containers/Studies/Recruit/components/Summary/SummaryItemCustomTerms';
import { SummaryItemIndustries } from 'containers/Studies/Recruit/components/Summary/SummaryItemIndustries';
import { SummaryItemParticipants } from 'containers/Studies/Recruit/components/Summary/SummaryItemParticipants';
import { SummaryItemPrimaryContact } from 'containers/Studies/Recruit/components/Summary/SummaryItemPrimaryContact';
import { SummaryItemScreenerQuestion } from 'containers/Studies/Recruit/components/Summary/SummaryItemScreenerQuestion';
import { useStudyContext } from 'containers/Studies/StudiesContainer';

import type { RecruitFormFields } from 'containers/Studies/data/schemas/recruitSchema';
import type { UseFormReturn } from 'react-hook-form';

interface RecruitPreviewProps {
  form: UseFormReturn<RecruitFormFields>;
}

export const RecruitPreview = ({ form }: RecruitPreviewProps) => {
  const { t } = useTranslation();
  const { study } = useStudyContext();

  return (
    <div className="mx-auto flex max-w-[42.5rem] flex-col gap-12 text-sm">
      <FormProvider {...form}>
        <SectionContainer id="section-participants">
          <SummaryItemParticipants />

          <SummaryItem
            label={t('sections.studies.recruit.fields.deviceTitle')}
            type="device"
            options={[t(`sections.studies.recruit.options.device.${study.recruit?.device ?? 'any_device'}`)]}
          />
        </SectionContainer>

        <SectionContainer
          id="section-audience"
          title={t('sections.studies.recruit.audience')}
          icon={<Target size={28} />}
        >
          <SummaryItem
            label={t('sections.studies.recruit.fields.locationsTitle')}
            type="locations"
            options={study.recruit?.locations?.map(location => location?.location?.formatted_address!)}
          />

          <SummaryItem
            label={t('sections.studies.recruit.fields.ageRangeTitle')}
            type="age_range"
            options={study.recruit?.age_range}
            variant="dash-between"
          />

          <SummaryItem
            label={t('sections.studies.recruit.fields.genderTitle')}
            type="gender"
            options={
              study.recruit?.gender && study.recruit.gender.length > 0
                ? study.recruit?.gender.map(val => t(`sections.studies.recruit.options.gender.${val}`))
                : [t('sections.studies.recruit.options.gender.any_gender')]
            }
          />

          <SummaryItemIndustries options={study.recruit?.industry_and_occupation ?? []} />

          <SummaryItem
            label={t('sections.studies.recruit.fields.languagesTitle')}
            type="languages"
            options={
              study.recruit?.languages ? study.recruit?.languages.map(val => t(`global.locale.${val.locale}`)) : null
            }
          />

          <SummaryItem
            label={t('sections.studies.recruit.fields.educationLevelTitle')}
            type="education_level"
            options={
              study.recruit?.education_level?.length
                ? study.recruit?.education_level.map(val =>
                    t(`sections.studies.recruit.options.education_level.${val}`),
                  )
                : null
            }
          />

          <SummaryItem
            label={t('sections.studies.recruit.fields.maritalStatusTitle')}
            type="marital_status"
            options={
              study.recruit?.marital_status?.length
                ? study.recruit?.marital_status.map(val => t(`sections.studies.recruit.options.marital_status.${val}`))
                : null
            }
          />

          <SummaryItem
            label={t('sections.studies.recruit.fields.occupationalStatusTitle')}
            type="occupational_status"
            options={
              study.recruit?.occupational_status?.length
                ? study.recruit?.occupational_status.map(val =>
                    t(`sections.studies.recruit.options.occupational_status.${val}`),
                  )
                : null
            }
          />

          <SummaryItem
            label={t('sections.studies.recruit.fields.businessOwnershipTitle')}
            type="business_ownership"
            options={
              study.recruit?.business_ownership?.length
                ? study.recruit?.business_ownership.map(val =>
                    t(`sections.studies.recruit.options.business_ownership.${val}`),
                  )
                : null
            }
          />
        </SectionContainer>

        {study.recruit?.screener_questions && study.recruit?.screener_questions?.length > 0 ? (
          <SectionContainer id="section-screener-questions">
            <h3 className="flex items-center text-base font-semibold">
              {t('sections.studies.recruit.fields.screenerQuestionsTitle')}
            </h3>

            <ul className="flex flex-col gap-4">
              {study.recruit?.screener_questions.map(question => (
                <SummaryItemScreenerQuestion key={question._id} question={question} />
              ))}
            </ul>
          </SectionContainer>
        ) : null}

        <SectionContainer id="section-settings" title={t('sections.studies.recruit.settings')} icon={<Cog size={28} />}>
          <SummaryItem
            label={t('sections.studies.recruit.fields.participantSelectionTitle')}
            type="participant_selection"
            options={
              study.recruit?.participant_selection
                ? [t(`sections.studies.recruit.options.participant_selection.${study.recruit?.participant_selection}`)]
                : null
            }
          />
          <SummaryItem
            label={t('sections.studies.recruit.fields.participantSourceTitle')}
            type="participant_source"
            options={
              study.recruit?.participant_source
                ? [t(`sections.studies.recruit.options.participant_source.${study.recruit?.participant_source}`)]
                : null
            }
          />
          <SummaryItem
            label={t('sections.studies.recruit.fields.participantsExcludedTitle')}
            type="participants_excluded_months"
            options={
              study.recruit?.participants_excluded_months
                ? [
                    t(
                      `sections.studies.recruit.options.participants_excluded_months.months_${study.recruit?.participants_excluded_months}` as const,
                    ) as string,
                  ]
                : null
            }
          />
          {study.contact?._primary_contact_id ? (
            <SummaryItemPrimaryContact userId={study.contact?._primary_contact_id} />
          ) : null}

          {study.recruit?.custom_terms?._template_id ? (
            <SummaryItemCustomTerms studyId={study?._id} templateId={study.recruit?.custom_terms?._template_id} />
          ) : null}
        </SectionContainer>

        <SectionContainer
          id="section-opportunity-listing"
          title={t('sections.studies.recruit.opportunityListing')}
          icon={<Explore size={28} />}
        >
          <div className="flex flex-col gap-1">
            <Label>{t('sections.studies.recruit.fields.titleTitle')}</Label>
            {study.listing_information?.title ? (
              <div className="rounded-md border border-border bg-accent p-2 leading-none text-foreground lg:text-sm">
                {study.listing_information?.title}
              </div>
            ) : (
              <div>-</div>
            )}
          </div>

          <div className="flex flex-col gap-2">
            <Label>{t('sections.studies.recruit.fields.descriptionTitle')}</Label>
            {study.listing_information?.description ? (
              <div className="rounded-md border border-border bg-accent p-2 leading-tight text-foreground lg:text-sm">
                {study.listing_information?.description}
              </div>
            ) : (
              <div>-</div>
            )}
          </div>
        </SectionContainer>
      </FormProvider>
    </div>
  );
};

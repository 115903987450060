import { toast } from '@askable/ui/core/sonner';
import { zodResolver } from '@hookform/resolvers/zod';
import { t } from 'i18next';
import { useEffect, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { removeTypeNames } from 'shared-utils';
import { match } from 'ts-pattern';
import { CombinedError, useMutation } from 'urql';

import { UpdateTaskBlockFigmaPrototype } from 'containers/Studies/BuildStudy/data/UpdateTaskBlockFigmaPrototype.mutation';
import { UpdateTaskBlockMultipleChoiceQuestion } from 'containers/Studies/BuildStudy/data/UpdateTaskBlockMultipleChoiceQuestion.mutation';
import { UpdateTaskBlockOpenAnswer } from 'containers/Studies/BuildStudy/data/UpdateTaskBlockOpenAnswer.mutation';
import { UpdateTaskBlockOpinionScale } from 'containers/Studies/BuildStudy/data/UpdateTaskBlockOpinionScale.mutation';
import { UpdateUnmoderatedBookingConfig } from 'containers/Studies/BuildStudy/data/UpdateUnmoderatedBookingConfig.mutation';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { blockSchema } from 'containers/Studies/data/schemas/blockSchema';
import { useFormAutoSave } from 'containers/Studies/utils/useFormAutoSave';
import { SystemBlockType, TaskBlockType, OpinionScaleType } from 'generated/graphql';

import type { ActiveBlock } from '../hooks/useActiveBlockId';
import type {
  multipleChoiceQuestionSchema,
  openAnswerSchema,
  opinionScaleSchema,
} from 'containers/Studies/data/schemas/blockSchema';
import type { ReactNode } from 'react';
import type { z } from 'zod';

export type BlockFormFields = z.infer<typeof blockSchema>;
export type MultipleChoiceQuestionFields = z.infer<typeof multipleChoiceQuestionSchema>;
export type OpenAnswerFields = z.infer<typeof openAnswerSchema>;
export type OpinionScaleFields = z.infer<typeof opinionScaleSchema>;

interface Props {
  children: ReactNode;
  activeBlock: NonNullable<ActiveBlock>;
  activeBlockId: string;
}

export const BlockForm = ({ children, activeBlock, activeBlockId }: Props) => {
  const [, updateUnmoderatedBookingConfig] = useMutation(UpdateUnmoderatedBookingConfig);
  const [, updateTaskBlockFigmaPrototype] = useMutation(UpdateTaskBlockFigmaPrototype);
  const [, updateTaskBlockMultipleChoiceQuestion] = useMutation(UpdateTaskBlockMultipleChoiceQuestion);
  const [, updateTaskBlockOpenAnswer] = useMutation(UpdateTaskBlockOpenAnswer);
  const [, updateTaskBlockOpinionScale] = useMutation(UpdateTaskBlockOpinionScale);

  const { newBlockId, setIsSaving, studyId } = useStudyContext();
  const form = useForm<BlockFormFields>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: zodResolver(blockSchema),
    defaultValues: {
      type: activeBlock.type as unknown as z.infer<typeof blockSchema>['type'], // @todo: is fixed in ASK-10264
      instructions: activeBlock.instructions,
      image_path: 'image_path' in activeBlock ? activeBlock.image_path : undefined,
      is_recording_enabled: 'is_recording_enabled' in activeBlock ? activeBlock.is_recording_enabled : false,
      title: activeBlock.title,
      figma_prototype:
        'figma_prototype' in activeBlock
          ? {
              file_id: activeBlock.figma_prototype?.file_id ?? '',
              start_screen_id: activeBlock.figma_prototype?.start_screen_id ?? '',
              goal_screen_id: activeBlock.figma_prototype?.goal_screen_id ?? '',
            }
          : undefined,
      multiple_choice_question:
        'multiple_choice_question' in activeBlock
          ? {
              has_other_option: activeBlock.multiple_choice_question?.has_other_option ?? false,
              is_multiple_select: activeBlock.multiple_choice_question?.is_multiple_select ?? false,
              is_randomised_order: activeBlock.multiple_choice_question?.is_randomised_order ?? false,
              options: activeBlock.multiple_choice_question?.options ?? [],
            }
          : undefined,
      open_answer:
        'open_answer' in activeBlock
          ? {
              max_length: activeBlock.open_answer?.max_length ?? 0,
            }
          : undefined,
      opinion_scale:
        'opinion_scale' in activeBlock
          ? {
              is_zero_start: activeBlock.opinion_scale?.is_zero_start ?? false,
              label_low: activeBlock.opinion_scale?.label_low ?? '',
              label_mid: activeBlock.opinion_scale?.label_mid ?? '',
              label_high: activeBlock.opinion_scale?.label_high ?? '',
              scale_max: activeBlock.opinion_scale?.scale_max ?? 5,
              scale_type: activeBlock.opinion_scale?.scale_type ?? OpinionScaleType.Numerical,
            }
          : undefined,
    },
  });

  // Validate form immediately if user is not currently creating a new one
  useEffect(() => {
    if ('_id' in activeBlock && activeBlock._id !== newBlockId) {
      form.trigger();
    }
  }, [activeBlock, form, newBlockId]);

  const handleSubmit = useCallback(
    async ({ values }: { values: BlockFormFields }) => {
      try {
        setIsSaving(true);

        // System blocks (welcome | thankyou) are stored in the study config, so we handle
        // them differently here
        const res = await match(values.type)
          .returnType<Promise<any> | void>()
          .with(SystemBlockType.Welcome, () =>
            updateUnmoderatedBookingConfig({
              input: {
                _id: studyId,
                welcome_block: {
                  title: values.title,
                  instructions: values.instructions || '',
                },
              },
            }),
          )
          .with(SystemBlockType.ThankYou, () =>
            updateUnmoderatedBookingConfig({
              input: {
                _id: studyId,
                thank_you_block: {
                  title: values.title,
                  instructions: values.instructions || '',
                },
              },
            }),
          )
          .with(TaskBlockType.FigmaPrototype, async () =>
            updateTaskBlockFigmaPrototype({
              input: {
                _id: studyId,
                task_block: {
                  _id: activeBlockId,
                  title: values.title,
                  instructions: values.instructions,
                  image_path: 'image_path' in values ? values.image_path : undefined,
                  is_recording_enabled: 'is_recording_enabled' in values ? values.is_recording_enabled : false,
                  figma_prototype:
                    'figma_prototype' in values && values.figma_prototype?.file_id
                      ? {
                          file_id: values.figma_prototype.file_id,
                          start_screen_id: values.figma_prototype.start_screen_id,
                          goal_screen_id: values.figma_prototype.goal_screen_id || null,
                        }
                      : // TODO: check if we can/want to fix the gql schema to make the `figma_prototype` field nullable
                        // right now things break if we set it to null and then attempt to update it to an object :|
                        {
                          file_id: null,
                          start_screen_id: null,
                          goal_screen_id: null,
                        },
                },
              },
            }),
          )
          .with(TaskBlockType.MultipleChoiceQuestion, () => {
            let multipleChoiceQuestion = undefined;

            if ('multiple_choice_question' in values && values.multiple_choice_question) {
              const mcq = { ...values.multiple_choice_question };

              // Clean __typename from options array if it exists
              if (mcq.options && Array.isArray(mcq.options)) {
                mcq.options = mcq.options.map(option => removeTypeNames(option));
              }

              multipleChoiceQuestion = mcq;
            }

            return updateTaskBlockMultipleChoiceQuestion({
              input: {
                _id: studyId,
                task_block: {
                  _id: activeBlockId,
                  title: values.title,
                  instructions: values.instructions,
                  image_path: 'image_path' in values ? values.image_path : undefined,
                  multiple_choice_question: multipleChoiceQuestion,
                },
              },
            });
          })
          .with(TaskBlockType.OpenAnswer, () =>
            updateTaskBlockOpenAnswer({
              input: {
                _id: studyId,
                task_block: {
                  _id: activeBlockId,
                  title: values.title,
                  instructions: values.instructions,
                  open_answer: 'open_answer' in values ? values.open_answer : undefined,
                },
              },
            }),
          )
          .with(TaskBlockType.OpinionScale, () =>
            updateTaskBlockOpinionScale({
              input: {
                _id: studyId,
                task_block: {
                  _id: activeBlockId,
                  title: values.title,
                  instructions: values.instructions,
                  image_path: 'image_path' in values ? values.image_path : undefined,
                  opinion_scale:
                    'opinion_scale' in values
                      ? {
                          ...values.opinion_scale,
                          label_mid: values.opinion_scale?.label_mid ?? '',
                          label_low: values.opinion_scale?.label_low ?? '',
                          label_high: values.opinion_scale?.label_high ?? '',
                        }
                      : undefined,
                },
              },
            }),
          )
          .otherwise(() => {
            // Unsupported type
          });

        if (!res || res?.error) {
          throw new Error(res?.error.message || 'Update failed');
        }
      } catch (err) {
        toast.error(err instanceof CombinedError ? err.message : t('sections.errorBoundary.default'));
      } finally {
        setTimeout(() => setIsSaving(false), 200);
      }
    },
    [
      activeBlockId,
      setIsSaving,
      studyId,
      updateTaskBlockFigmaPrototype,
      updateTaskBlockMultipleChoiceQuestion,
      updateTaskBlockOpenAnswer,
      updateTaskBlockOpinionScale,
      updateUnmoderatedBookingConfig,
    ],
  );

  // Auto submit the form on every change
  useFormAutoSave({ form, handleSubmit, preventTrigger: activeBlockId === newBlockId });

  return <FormProvider {...form}>{children}</FormProvider>;
};

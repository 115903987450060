import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InfoAlert } from 'containers/Studies/components/InfoAlert';
import {
  PARTICIPANT_SELECTION_OPTIONS,
  PARTICIPANT_SOURCE_OPTIONS,
  PARTICIPANTS_EXCLUDED_OPTIONS,
} from 'containers/Studies/data/schemas/recruitSchema';

import { CustomTerms } from './CustomTerms';
import { RadioGroupField } from './Fields/RadioGroupField';
import { SelectField } from './Fields/SelectField';
import { SwitchField } from './Fields/SwitchField';
import { PrimaryContact } from './PrimaryContact';

import type { RecruitFormFields } from 'containers/Studies/data/schemas/recruitSchema';

export const SectionSettings = ({ isDisabled }: { isDisabled?: boolean }) => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<RecruitFormFields>();

  const participantSource = watch('participant_source');
  const enableCustomTerms = watch('enable_custom_terms');

  // TODO: this should not be a side effect, it should be part of the switch handler
  useEffect(() => {
    if (!enableCustomTerms) {
      // TODO: discuss with design, right now we're resetting the custom terms fields when the user
      // toggles this on or off, which feels it would be pretty frustrating for misclicks
      setValue('custom_terms_template_id', null, { shouldDirty: true });
      setValue('custom_terms_recipients', '', { shouldDirty: true });
    }
  }, [enableCustomTerms, setValue]);

  const participantSelectionOptions = PARTICIPANT_SELECTION_OPTIONS.map(option => ({
    label: t(`sections.studies.recruit.options.participant_selection.${option}`),
    value: option,
  }));

  const participantSourceOptions = PARTICIPANT_SOURCE_OPTIONS.map(option => ({
    label: t(`sections.studies.recruit.options.participant_source.${option}`),
    value: option,
  }));

  const participantsExcludedOptions = PARTICIPANTS_EXCLUDED_OPTIONS.map(option => ({
    label: t(
      `sections.studies.recruit.options.participants_excluded_months.${option ? `months_${option}` : 'months_null'}`,
    ),
    value: option,
  }));

  return (
    <>
      <RadioGroupField
        name="participant_selection"
        label={t('sections.studies.recruit.fields.participantSelectionTitle')}
        options={participantSelectionOptions}
        infoPopover={{
          title: t('sections.studies.recruit.fields.participantSelectionCalloutTitle'),
          description: t('sections.studies.recruit.fields.participantSelectionCalloutDescription'),
          moreLink: 'https://help.askable.com/en/articles/4293041-automated-vs-hand-picking',
        }}
        variant="inline"
        isDisabled={isDisabled}
      />

      <hr className="my-1 border-t-0.5 border-secondary p-0" />

      <SelectField
        name="participants_excluded_months"
        label={t('sections.studies.recruit.fields.participantsExcludedTitle')}
        description={t('sections.studies.recruit.fields.participantsExcludedDescription')}
        options={participantsExcludedOptions}
        variant="inline"
        isDisabled={isDisabled}
      />

      <hr className="my-1 border-t-0.5 border-secondary p-0" />

      <SelectField
        name="participant_source"
        label={t('sections.studies.recruit.fields.participantSourceTitle')}
        description={t('sections.studies.recruit.fields.participantSourceDescription')}
        infoPopover={{
          title: t('sections.studies.recruit.fields.participantSourceCalloutTitle'),
          description: t('sections.studies.recruit.fields.participantSourceCalloutDescription'),
          moreLink: 'https://help.askable.com/en/articles/4849168-how-does-askable-source-their-participants',
        }}
        options={participantSourceOptions}
        variant="inline"
        isDisabled={isDisabled}
      />

      {participantSource === 'list' ? (
        <InfoAlert
          title={t('sections.studies.recruit.fields.participantSourceListCalloutTitle')}
          description={t('sections.studies.recruit.fields.participantSourceListCalloutDescription')}
          id="participant_source_info"
          moreLink="https://help.askable.com/en/articles/3777041-byo-i-want-to-recruit-my-own-participants"
        />
      ) : null}

      <hr className="my-1 border-t-0.5 border-secondary p-0" />

      <PrimaryContact isDisabled={isDisabled} />

      <hr className="my-1 border-t-0.5 border-secondary p-0" />

      <SwitchField
        name="enable_custom_terms"
        label={t('sections.studies.recruit.fields.customTermsTitle')}
        description={t('sections.studies.recruit.fields.customTermsDescription')}
        size="lg"
        isDisabled={isDisabled}
      />

      {enableCustomTerms ? <CustomTerms isDisabled={isDisabled} /> : null}
    </>
  );
};

import { useEffect, useMemo, useState } from 'react';

import { Checkbox } from '../core/checkbox';
import { Input } from '../core/input';
import { Label } from '../core/label';
import { RadioGroup, RadioGroupItem } from '../core/radio-group';
import { cn } from '../lib/utils';

interface OptionItem {
  _id: string;
  value: string;
}

interface MultipleChoiceQuestionProps {
  hasOtherOption: boolean;
  isMultipleSelect: boolean;
  isRandomisedOrder: boolean;
  labels?: {
    other: string;
    input: string;
  };
  options: OptionItem[];
  onChange: (options: OptionItem[]) => void;
  onOtherChange?: (value: string) => void;
}

const Option = ({
  option,
  selected,
  isMultipleSelect,
  onSelect,
}: {
  option: OptionItem;
  selected: string[];
  isMultipleSelect: boolean;
  onSelect: (id: string) => void;
}) => {
  return (
    <Label
      htmlFor={`multiple_choice_question_${option._id}`}
      className={cn(
        `flex w-full cursor-pointer items-center justify-between gap-2 rounded-lg border border-border p-3 hover:border-input
        focus:border-input active:border-input`,
        {
          'border-primary ring-0.5 ring-primary hover:border-primary focus:border-primary active:border-primary':
            selected.includes(option._id),
        },
      )}
    >
      <div className="flex items-start gap-2">
        {isMultipleSelect ? (
          <Checkbox
            id={`multiple_choice_question_${option._id}`}
            checked={selected.includes(option._id)}
            onCheckedChange={() => onSelect(option._id)}
          />
        ) : (
          <RadioGroupItem value={option._id} id={`multiple_choice_question_${option._id}`} />
        )}
        <span className="-my-[1px] text-base leading-snug">{option.value}</span>
      </div>
    </Label>
  );
};

export const MultipleChoiceQuestion = ({
  hasOtherOption,
  isMultipleSelect,
  isRandomisedOrder,
  labels = { other: 'Other', input: 'Type your answer here...' },
  options,
  onChange,
  onOtherChange,
}: MultipleChoiceQuestionProps) => {
  const [selected, setSelected] = useState<string[]>([]);

  const handleChange = (id: string) => {
    let newSelected = [...selected];

    if (isMultipleSelect) {
      // For checkbox behavior
      if (selected.includes(id)) {
        newSelected = selected.filter(selectedId => selectedId !== id);
      } else {
        newSelected = [...selected, id];
      }
    } else {
      // For radio button behavior
      newSelected = [id];
    }

    setSelected(newSelected);
    onChange(options.filter(option => newSelected.includes(option._id)));
  };

  useEffect(() => {
    setSelected([]);
  }, [isMultipleSelect]);

  const localOptions = useMemo(
    () => (isRandomisedOrder ? [...options].sort(() => Math.random() - 0.5) : options).filter(o => o.value !== ''),
    [options, isRandomisedOrder],
  );

  const otherOption = { _id: 'other', value: labels.other };

  return (
    <div className="flex flex-col gap-4 overflow-auto p-[1px] text-foreground">
      {isMultipleSelect ? (
        <div className="group flex flex-col flex-wrap gap-4">
          {localOptions.map(option => (
            <Option
              key={option._id}
              option={option}
              selected={selected}
              isMultipleSelect={true}
              onSelect={handleChange}
            />
          ))}
          {hasOtherOption ? (
            <Option option={otherOption} selected={selected} isMultipleSelect={true} onSelect={handleChange} />
          ) : null}
        </div>
      ) : (
        <RadioGroup
          className="group flex flex-col flex-wrap gap-4"
          id="multiple_choice_question"
          onValueChange={value => handleChange(value)}
        >
          {localOptions.map(option => (
            <Option
              key={option._id}
              option={option}
              selected={selected}
              isMultipleSelect={false}
              onSelect={handleChange}
            />
          ))}
          {hasOtherOption ? (
            <Option option={otherOption} selected={selected} isMultipleSelect={false} onSelect={handleChange} />
          ) : null}
        </RadioGroup>
      )}

      {hasOtherOption && selected.includes('other') ? (
        <>
          <Label htmlFor="input_other_option" className="sr-only">
            {labels.other}
          </Label>
          <Input
            autoFocus
            className="p-3 text-base"
            id="input_other_option"
            placeholder={labels.input}
            onChange={e => onOtherChange?.(e.target.value)}
          />
        </>
      ) : null}
    </div>
  );
};

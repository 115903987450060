import { Dialog, DialogContent } from '@askable/ui/core/dialog';
import { cn } from '@askable/ui/lib/utils';
import { useFeatureFlags } from 'feature-flags';
import { useRef, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { match } from 'ts-pattern';

import { EmptyState } from 'containers/Studies/Results/components/EmptyState';
import { FigmaPrototypeResultDialogContentIndividual } from 'containers/Studies/Results/components/Task/FigmaPrototype/Result/FigmaPrototypeResultDialogContentIndividual';
import { FigmaPrototypeResultDialogContentPath } from 'containers/Studies/Results/components/Task/FigmaPrototype/Result/FigmaPrototypeResultDialogContentPath';
import { FigmaPrototypeResultDialogContentScreen } from 'containers/Studies/Results/components/Task/FigmaPrototype/Result/FigmaPrototypeResultDialogContentScreen';

export type FigmaPrototypeResultType = 'individual' | 'screen' | 'path';

export const FigmaPrototypeResultDialog = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { UNMODERATED_STUDY_DEV } = useFeatureFlags(['UNMODERATED_STUDY_DEV']);
  const closeTimeoutRef = useRef<number | null>(null);

  // "type" param in the URL needs to be set of the dialog won't open
  // Fallback to 'individual' if no type is specified
  const type =
    (searchParams.get('type') as FigmaPrototypeResultType) ||
    (searchParams.has('result_id') ? 'individual' : undefined);

  const activeTab = searchParams.get('tab');

  const hasRelevantParams =
    UNMODERATED_STUDY_DEV &&
    searchParams.has('type') &&
    (searchParams.has('node_id') ||
      searchParams.has('path_id') ||
      searchParams.has('path_index') ||
      searchParams.has('path_item_id') ||
      searchParams.has('result_id') ||
      searchParams.has('user_id'));

  const [isOpen, setIsOpen] = useState(hasRelevantParams);

  // Update dialog state when URL params change, but not in the middle of closing
  useEffect(() => {
    if (!closeTimeoutRef.current && isOpen !== hasRelevantParams) {
      setIsOpen(hasRelevantParams);
    }
  }, [hasRelevantParams, isOpen]);

  useEffect(() => {
    return () => {
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current);
      }
    };
  }, []);

  const handleClose = () => {
    setIsOpen(false);

    // Use ref to track the timeout and prevent a race condition
    closeTimeoutRef.current = window.setTimeout(() => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('heatmap_view');
      newSearchParams.delete('node_id');
      newSearchParams.delete('path_id');
      newSearchParams.delete('path_index');
      newSearchParams.delete('path_item_id');
      newSearchParams.delete('result_id');
      newSearchParams.delete('tab');
      newSearchParams.delete('type');
      newSearchParams.delete('user_id');
      setSearchParams(newSearchParams);

      closeTimeoutRef.current = null;
    }, 300);
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={open => {
        if (!open) {
          handleClose();
        }
      }}
    >
      <DialogContent
        onEscapeKeyDown={event => event.preventDefault()}
        onOpenAutoFocus={event => event.preventDefault()}
        className={cn(
          'bottom-[unset] top-6 flex min-h-80 translate-y-0 flex-col gap-0 border-0 p-0',
          'data-[state=open]:slide-in-from-top-1 sm:max-h-[calc(100%_-3rem)] sm:max-w-[calc(100%_-2rem)]',
          { 'h-full': activeTab === 'heatmap' },
        )}
      >
        {UNMODERATED_STUDY_DEV ? (
          <>
            {match(type)
              .with('individual', () => <FigmaPrototypeResultDialogContentIndividual />)
              .with('path', () => <FigmaPrototypeResultDialogContentPath />)
              .with('screen', () => <FigmaPrototypeResultDialogContentScreen />)
              .otherwise(() => (
                <EmptyState />
              ))}
          </>
        ) : (
          <FigmaPrototypeResultDialogContentIndividual />
        )}
      </DialogContent>
    </Dialog>
  );
};

/* eslint-disable max-lines */
import isAfter from 'date-fns/isAfter';
import { t } from 'i18next';
import { memo, sort } from 'radash';
import { formatSessionTime } from 'shared-utils';

import { ApplicantEmploymentType, SegmentFilterMethod, SegmentSortValue, SubmissionStatus } from 'generated/graphql';
import { bookingUtils } from 'lib/booking';
import countries from 'lib/data/phoneCountryCodes.json';
import { userUtils } from 'lib/user';
import { isBookingForProfessionals, isModeratedBooking } from 'utils/booking-utils';

import type {
  BookingContainerBookingQuery,
  BookingSession,
  IndustryType,
  Maybe,
  Segment,
  SegmentFilter,
  SegmentFilterInput,
} from 'generated/graphql';
import type { MultiValue, SingleValue } from 'react-select';
import type { EnglishProficiency, Genders } from 'types/models';

type FieldOptions = 'text' | 'multi_select' | 'number';

export type SingleChangeValue<T = string> = SingleValue<{
  label: string;
  value: T;
}>;

export type MultiChangeValue = MultiValue<{
  label: string;
  value: string;
}>;

export type FilterOption<T> = {
  value: T;
  label: string;
};

export type Filter<T = string> = {
  /**
   * This is generated id used to track for updates
   */
  id?: string;
  value: Maybe<MultiChangeValue>;
  field: SingleChangeValue;
  constraint: SingleChangeValue<SegmentFilterMethod>;
  fieldType: Maybe<FieldOptions>;
  options?: FilterOption<T>[];
  config: {
    constraintOptions: { value: SegmentFilterMethod; label: string }[];
  };
};

export type Sort = SegmentSortValue[];

export const SELECT_CONSTRAINTS: { value: SegmentFilterMethod; label: string }[] = [
  { label: 'is any of', value: SegmentFilterMethod.AnyOf },
  { label: 'is none of ', value: SegmentFilterMethod.NoneOf },
];

const FILTER_CONSTRAINTS = {
  multi_select: SELECT_CONSTRAINTS,
  text: [
    { label: 'contains', value: SegmentFilterMethod.Contains },
    { label: 'does not contain', value: SegmentFilterMethod.DoesNotContain },
  ],
  number: [
    { label: 'equals', value: SegmentFilterMethod.EqualTo },
    { label: 'does not equal', value: SegmentFilterMethod.NotEqualTo },
    { label: 'less than', value: SegmentFilterMethod.LessThan },
    { label: 'greater than', value: SegmentFilterMethod.GreaterThan },
    { label: 'is less than or equal to', value: SegmentFilterMethod.LessOrEqualTo },
    { label: 'is greater than or equal to', value: SegmentFilterMethod.GreaterOrEqualTo },
  ],
};

const STATIC_FILTER_OPTIONS = [
  { label: 'Status', value: 'status' },
  { label: 'Shortlisted', value: 'shortlisted' },
  { label: 'Name', value: 'name' },
  { label: 'Eligibility', value: 'eligibility' },
  { label: 'Age', value: 'age' },
  { label: 'Gender', value: 'gender' },
  { label: 'Location', value: 'location' },
];

const STATUS_OPTIONS: FilterOption<SubmissionStatus>[] = [
  {
    label: 'Available',
    value: SubmissionStatus.Available,
  },
  {
    label: 'Invited',
    value: SubmissionStatus.Invited,
  },
  {
    label: 'Confirmed',
    value: SubmissionStatus.Confirmed,
  },
  {
    label: 'Completed',
    value: SubmissionStatus.Completed,
  },
  {
    label: 'Declined',
    value: SubmissionStatus.InviteDeclined,
  },
  {
    label: 'Cancelled',
    value: SubmissionStatus.ParticipantCancelled,
  },
  {
    label: 'Waitlisted',
    value: SubmissionStatus.Waitlisted,
  },
  {
    label: 'Reported',
    value: SubmissionStatus.Reported,
  },
  {
    value: SubmissionStatus.PendingCheck,
    label: 'Completed - Pending Check',
  },
  {
    value: SubmissionStatus.HelpRequested,
    label: 'Help requested',
  },
  {
    value: SubmissionStatus.TimeExpired,
    label: 'Time expired',
  },
  {
    value: SubmissionStatus.InProgress,
    label: 'In progress',
  },
];

const EXCLUDE_UNMOD = [
  SubmissionStatus.Confirmed,
  SubmissionStatus.InviteDeclined,
  SubmissionStatus.ParticipantCancelled,
];
const UNMODERATED_STATUS_OPTIONS: FilterOption<SubmissionStatus>[] = STATUS_OPTIONS.filter(
  opt => !EXCLUDE_UNMOD.includes(opt.value),
);

const EXCLUDE_MOD = [
  SubmissionStatus.HelpRequested,
  SubmissionStatus.TimeExpired,
  SubmissionStatus.PendingCheck,
  SubmissionStatus.InProgress,
];
const MODERATED_STATUS_OPTIONS: FilterOption<SubmissionStatus>[] = STATUS_OPTIONS.filter(
  opt => !EXCLUDE_MOD.includes(opt.value),
);

function mapToOption({ label, id }: { label: string; id: string }): FilterOption<string> {
  return {
    label,
    value: id,
  };
}

const MARITAL_STATUS_OPTIONS = userUtils.maritalStatus().map(mapToOption);
const WORK_TYPE_OPTIONS = userUtils.employmentStatus().map(mapToOption);
const EMPLOYMENT_TYPE_OPTIONS: { value: ApplicantEmploymentType; label: string }[] = [
  { value: ApplicantEmploymentType.BusinessOwner, label: 'Business Owner' },
  { value: ApplicantEmploymentType.Employee, label: 'Employee' },
  { value: ApplicantEmploymentType.SelfEmployed, label: 'Self employed' },
];

const ENGLISH_LEVEL_OPTIONS: FilterOption<EnglishProficiency>[] = [
  {
    label: 'Native',
    value: 'native',
  },
  {
    label: 'Fluent',
    value: 'fluent',
  },
  {
    value: 'conversational',
    label: 'Conversational',
  },
  {
    value: 'beginner',
    label: 'Beginner',
  },
];

const GENDER_OPTIONS: FilterOption<Genders>[] = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'Non-Binary',
    value: 'non-binary',
  },
];

const SHORTLIST_OPTIONS: FilterOption<string>[] = [
  {
    label: 'true',
    value: 'true',
  },
  {
    label: 'false',
    value: 'false',
  },
];

type StaticSelectOptions = {
  gender: {
    moderated: FilterOption<string>[];
    unmoderated: FilterOption<string>[];
  };
  status: {
    moderated: FilterOption<SubmissionStatus>[];
    unmoderated: FilterOption<SubmissionStatus>[];
  };
  shortlisted: {
    moderated: FilterOption<string>[];
    unmoderated: FilterOption<string>[];
  };
  work_type: {
    moderated: FilterOption<string>[];
    unmoderated: FilterOption<string>[];
  };
  employment_type: {
    moderated: FilterOption<ApplicantEmploymentType>[];
    unmoderated: FilterOption<ApplicantEmploymentType>[];
  };
  english_level: {
    moderated: FilterOption<string>[];
    unmoderated: FilterOption<string>[];
  };
  family_status: {
    moderated: FilterOption<string>[];
    unmoderated: FilterOption<string>[];
  };
};

const SELECT_OPTIONS: StaticSelectOptions = {
  gender: {
    moderated: GENDER_OPTIONS,
    unmoderated: GENDER_OPTIONS,
  },
  status: {
    moderated: MODERATED_STATUS_OPTIONS,
    unmoderated: UNMODERATED_STATUS_OPTIONS,
  },
  shortlisted: {
    moderated: SHORTLIST_OPTIONS,
    unmoderated: SHORTLIST_OPTIONS,
  },
  work_type: {
    moderated: WORK_TYPE_OPTIONS,
    unmoderated: WORK_TYPE_OPTIONS,
  },
  employment_type: {
    moderated: EMPLOYMENT_TYPE_OPTIONS,
    unmoderated: EMPLOYMENT_TYPE_OPTIONS,
  },
  english_level: {
    moderated: ENGLISH_LEVEL_OPTIONS,
    unmoderated: ENGLISH_LEVEL_OPTIONS,
  },
  family_status: {
    moderated: MARITAL_STATUS_OPTIONS,
    unmoderated: MARITAL_STATUS_OPTIONS,
  },
};

export type FilterType<T = string> = {
  filterConfigs: { [key: string]: Filter };
  options: FilterOption<T>[];
};

export function getStaticFilters(bookingType?: 'moderated' | 'unmoderated'): FilterType {
  return STATIC_FILTER_OPTIONS.reduce<{
    filterConfigs: { [key: string]: Filter };
    options: FilterOption<string>[];
  }>(
    (acc, curr) => {
      const fieldType = mapFieldType(curr.value);

      acc.filterConfigs[curr.value] = {
        fieldType,
        config: {
          constraintOptions: FILTER_CONSTRAINTS[fieldType],
        },
        field: curr,
        value: null,
        constraint: null,
        options: SELECT_OPTIONS[curr.value as keyof typeof SELECT_OPTIONS]?.[bookingType ?? 'moderated'] ?? null,
      };

      return acc;
    },
    {
      filterConfigs: {},
      options: STATIC_FILTER_OPTIONS,
    },
  );
}

export function getCustomFilters(booking?: BookingContainerBookingQuery['bookingByID']): FilterType {
  const questions = booking?.config?.question ?? [];

  return questions.reduce<{
    filterConfigs: { [key: string]: Filter };
    options: FilterOption<string>[];
  }>(
    (acc, curr) => {
      acc.filterConfigs[curr?._id!] = {
        field: { value: curr?._id!, label: curr?.title! },
        value: null,
        constraint: null,
        options: curr?.options?.map(a => ({ value: a?._id!, label: a?.label! })) ?? [],
        fieldType: curr?.config?.type === 2 ? 'text' : 'multi_select',
        config: {
          constraintOptions: curr?.config?.type === 2 ? FILTER_CONSTRAINTS.text : SELECT_CONSTRAINTS,
        },
      };

      acc.options.push({
        value: curr?._id!,
        label: curr?.title!,
      });

      return acc;
    },
    { filterConfigs: {}, options: [] },
  );
}

function getIndustryFilters(industryList?: IndustryType[]): FilterType {
  return {
    options: [{ value: 'industry', label: 'Industry' }],
    filterConfigs: {
      industry: {
        constraint: null,
        id: 'industry',
        fieldType: 'multi_select',
        value: null,
        options: (industryList ?? [])?.map(a => {
          return {
            label: a.name!,
            value: a._id!,
          };
        }),
        field: {
          value: 'industry',
          label: 'Industry',
        },
        config: {
          constraintOptions: SELECT_CONSTRAINTS,
        },
      },
    },
  };
}

export function getBookingParticipantFilters(
  booking?: BookingContainerBookingQuery['bookingByID'],
  industryList?: IndustryType[],
): FilterType {
  const staticFilters = getStaticFilters(booking?.type === 1 || booking?.type === 2 ? 'moderated' : 'unmoderated');
  const customFilters = getCustomFilters(booking);
  const dynamicFilters = getDynamicFilters(booking);
  const industryFilters = getIndustryFilters(industryList);

  return {
    filterConfigs: {
      ...staticFilters.filterConfigs,
      ...dynamicFilters.filterConfigs,
      ...customFilters.filterConfigs,
      ...industryFilters.filterConfigs,
    },
    options: [
      ...staticFilters.options,
      ...dynamicFilters.options,
      ...industryFilters.options,
      ...customFilters.options,
    ],
  };
}

export function getDynamicFilters(booking?: BookingContainerBookingQuery['bookingByID']): FilterType {
  const config = getBookingColumnConfig(booking);
  const bookingType = isModeratedBooking(booking?.type) ? 'moderated' : 'unmoderated';
  const allCountries = bookingUtils.allCountries(booking?.config?.criteria?.locations);

  const options: {
    value: string;
    label: string;
    selectOptions?: { value: string; label: string }[];
  }[] = [];

  if (config.workStatus) {
    options.push({
      label: 'Work type',
      value: 'work_type',
    });
  }

  if (allCountries.length > 1) {
    options.push({
      label: 'Country',
      value: 'country',
      selectOptions: allCountries.map(country => {
        return {
          label: countries.find(({ region }) => region === country)?.name || country,
          value: country,
        };
      }),
    });
  }

  // is moderated
  if (bookingType === 'moderated') {
    const singularSessions = (booking?.session ?? [])?.reduce<Pick<BookingSession, '_id' | 'start' | 'end'>[]>(
      (acc, curr) => {
        if (!curr || acc.find(a => a?.start === curr?.start && a?.end === curr?.end)) {
          return acc;
        }

        if (isAfter(curr?.start!, new Date())) {
          acc.push(curr);
        }

        return acc;
      },
      [],
    );

    options.push({
      label: 'Preferred times',
      value: 'preferred_times',
      selectOptions: sort(singularSessions, a => a?.start!)?.map(session => {
        return {
          label: formatSessionTime({ start: session?.start!, end: session?.end! }),
          value: session?._id!,
        };
      }),
    });
  }

  if (config.locale) {
    options.push({
      label: 'Language',
      value: 'locale',
      selectOptions: booking?.config?.criteria?.meta_identity_locales
        ?.filter(locale => locale !== undefined && locale !== null)
        ?.map(locale => ({
          label: t(`global.locale.${locale.locale}`),
          value: locale.locale,
        })),
    });
  }

  if (config.familyStatus) {
    options.push({
      label: 'Marital status',
      value: 'family_status',
    });
  }

  if (config.englishLevel) {
    options.push({
      value: 'english_level',
      label: 'English level',
    });
  }

  if (config.employmentType) {
    options.push({
      label: 'Employment type',
      value: 'employment_type',
    });
  }

  const filterConfigs = options.reduce<FilterType['filterConfigs']>((acc, curr) => {
    const fieldType = mapFieldType(curr.value);

    acc[curr.value] = {
      fieldType,
      field: curr,
      value: null,
      constraint: null,
      options: curr?.selectOptions ?? SELECT_OPTIONS[curr.value as keyof typeof SELECT_OPTIONS][bookingType],
      config: {
        constraintOptions: FILTER_CONSTRAINTS[fieldType],
      },
    };
    return acc;
  }, {});

  return { options, filterConfigs };
}

// This has to be string because of custom fields
export function mapFieldType(v?: string) {
  switch (v) {
    case 'gender':
    case 'status':
    case 'executive_level':
    case 'employment':
    case 'marital_status':
    case 'shortlisted':
    case 'preferred_times':
    case 'english_level':
    case 'locale':
      return 'multi_select';
    case 'age':
    case 'eligibility':
      return 'number';
    case 'location':
    case 'name':
      return 'text';
    default:
      return 'multi_select';
  }
}

function mapValue(value?: string, field?: string): SegmentFilterInput['values'][0] {
  if (!value) {
    return {
      label: '',
      value_numeric: null,
      value_string: '',
    };
  }

  const isObjectId = /^[0-9a-f]{24}$/i.test(field ?? '');

  // Treat as string if it's NaN OR an ObjectID (meaning it's a custom field)
  const isStringValue = Number.isNaN(Number(value)) || isObjectId;

  if (isStringValue) {
    return {
      label: value,
      value_numeric: null,
      value_string: value,
    };
  }

  const numberValue = Number(value);

  return {
    label: value,
    value_string: null,
    value_numeric: field === 'eligibility' ? numberValue / 100 : numberValue,
  };
}

export function mapUIFiltersToSegmentFiltersInput(f: Filter[]): SegmentFilterInput[] {
  return (f ?? [])?.map(fil => {
    return {
      field: fil.field?.value ?? '',
      method: fil.constraint?.value!,
      values: (fil?.value ?? []).map(v => {
        return mapValue(v?.value, fil.field?.value);
      }),
    };
  });
}

export function mapUIFiltersToSegmentFilters(f: Filter[]): SegmentFilter[] {
  return (f ?? [])?.map(fil => {
    return {
      _id: fil.id!,
      field: fil.field?.value ?? '',
      method: fil.constraint?.value!,
      values: (fil?.value ?? []).map(v => {
        return mapValue(v?.value, fil.field?.value);
      }),
    };
  });
}

export function mapSegmentFiltersToUIFilters(
  filters: Segment['filters'],
  configs: FilterType<string> | null,
): Filter[] {
  return (filters ?? [])
    .map(filter => {
      if (!filter?.field || !filter?._id) {
        return null;
      }
      const selected = configs?.filterConfigs[filter?.field];
      if (!selected) {
        return null;
      }
      return {
        id: filter?._id,
        ...selected,
        constraint: selected?.config?.constraintOptions?.find(b => b.value === filter?.method)!,
        value:
          filter?.values.map(val => {
            switch (selected.fieldType) {
              case 'number':
                return {
                  label: val?.label!,
                  value:
                    filter.field === 'eligibility' && val?.value_numeric
                      ? `${val.value_numeric * 100}`
                      : `${val?.value_numeric}`,
                };
              case 'text':
                return { label: val?.label!, value: val?.value_string! };
              case 'multi_select':
                return selected.options?.find(b => b.value === val?.value_string)!;
              default:
                throw new Error(`Unimplemented filter type: ${selected.fieldType}`);
            }
          }) ?? [],
      };
    })
    .filter(Boolean) as Filter[];
}

export const DEFAULT_SORT: { moderated: SegmentSortValue[]; unmoderated: SegmentSortValue[] } = {
  moderated: [SegmentSortValue.Status, SegmentSortValue.Session],
  unmoderated: [SegmentSortValue.Status, SegmentSortValue.Eligibility],
};

export function formatEligibility(eligibility?: number | null): string {
  if (!eligibility) {
    return '';
  }

  return `${Math.round(eligibility * 100)}%`;
}

export type BookingColumnCustomFilterConfig = {
  workStatus: boolean;
  familyStatus: boolean;
  englishLevel: boolean;
  employmentType: boolean;
  industry: boolean;
  locale: boolean;
};

export const getBookingColumnConfig = memo(
  (booking: BookingContainerBookingQuery['bookingByID']): BookingColumnCustomFilterConfig => {
    return {
      employmentType: isBookingForProfessionals(booking),
      workStatus: booking?.config?.criteria?.meta_work_status !== null,
      familyStatus: booking?.config?.criteria?.meta_family_status !== null,
      industry: booking?.config?.criteria?.meta_work_industry !== null,
      englishLevel: booking?.config?.criteria?.meta_identity_languages_english_speak !== null,
      locale: !!booking?.config?.criteria?.meta_identity_locales?.length,
    };
  },
);
